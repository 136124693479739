<template>
  <!-- tabvertical이 있어야 탭에 테두리가 생김 -->
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :attachInfo1="attachInfo1"
          :attachInfo2="attachInfo2"
          :isApprDisabled="isApprDisabled"
          :height="height"
          @closePopup="closePopup"
          @changeStatus="changeStatus"
          @changeComplete="changeComplete"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'nearRegisterTab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        iimStatusNearCd: '',
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: 'nearRegisterInfo',
      tabItems: [
        { name: 'nearRegisterInfo', icon: 'edit', label: '기본정보', component: () => import(`${'./nearRegisterInfo.vue'}`) },
        { name: 'nearRelationPicture', icon: 'camera_enhance', label: '관련 사진', component: () => import(`${'./nearRelationPicture.vue'}`) }
      ],
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      },
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 850;
      numHeight = numHeight - 30
      return numHeight + 'px';
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting 
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    changeStatus() {
      this.$emit('changeStatus');
    },
    changeComplete() {
      this.popupParam.iimStatusNearCd = 'ISNC000002';
      this.$emit('changeComplete');
    },
  }
};
</script>