var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
    },
    [
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    attachInfo1: _vm.attachInfo1,
                    attachInfo2: _vm.attachInfo2,
                    isApprDisabled: _vm.isApprDisabled,
                    height: _vm.height,
                  },
                  on: {
                    closePopup: _vm.closePopup,
                    changeStatus: _vm.changeStatus,
                    changeComplete: _vm.changeComplete,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }